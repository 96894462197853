import Vue from 'vue'
import VueRouter from 'vue-router'
import asyncRoutes from './asyncRoutes'

Vue.use(VueRouter)

const routes = [
  ...asyncRoutes,
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404')
  }
]

// 解决路由重复点击时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  // mode: 'history'
})


//***********************************全局前置守卫

// 假设没登录时，非法进入管理主页面  "/"
// 假设没登录时，进入登录页  "/login"
// 假设登录过，进入管理页  "/"


// 路由拦截： 权限管理的相关内容
// 只检测是否有token, 只要有token就能导航到对应的路由组件, 不管token是否伪造或过期
// 所以，在请求后端接口时，还要再携带token,到后端验证token是否有效，有效才能获取数据
// 如果token无效，后端会返回401状态码，显示token失效

router.beforeEach((to, from, next) => {
  // to.fullPath = "/login?redirectUrl=%2Fnotice%2Findex"
  // to.fullPath = '/login'
  // path不带参数的url, fullPath是携带query参数的url
  // console.log('to',to);
  let token = sessionStorage.getItem("token")
  if (to.path === "/login") {
    next()
  } else {
    if (token) {
      next()
    } else {
      next("/login")
    }
  }
})

export default router
