export default  [
    // {
    //   // 管理后台的主页面
    //   path: '/',
    //   name: 'layout',
    //   meta: {
    //     title: '首页',
    //     icon: 'el-icon-menu',
    //     // 标记当前路由只有一级菜单，没有二级菜单
    //     level: 1,
    //     role: ['admin','teacher']
    //   },
    //   component: (() => import('../views/layout')),
    //   redirect: '/dashboard',
    //   children: [
    //     {
    //       path: 'dashboard',
    //       component: (() => import('../views/dashboard')),
    //     }
    //   ]
    // },
    {
      path: '/info',
      name: 'info',
      meta: {
        title: '信息管理',
        icon: 'el-icon-location',
        role: ['admin','teacher']
      },
      // 路由组件依然是layout
      component: (() => import('../views/layout')),
      children: [
        {
          path: 'info',
          component: (() => import('../views/info/info')),
          meta: {
            icon: "el-icon-help",
            title: '客户信息'
          }
        },

      ]
    },

]
