import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 进入编辑页面时的动作
    action: 'add',
    // 要回显的数据
    record: {
      gender: 0,
      paySatus: 0
    },
    //编辑完毕返回学员信息时要拉取的页码
    page2: 1
  },
  getters: {
  },
  mutations: {
    // 改变action
    CHANGE_ACTION: (state, action) => state.action = action,
    // 改变record
    CHANGE_RECORD: (state, record) => state.record = record,
    // 改变page
    CHANGE_PAGE2: (state, page2) => state.page2 = page2,
  },
  actions: {
  },
  modules: {
  }
})
