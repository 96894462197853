import Vue from 'vue'

// 引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//引入normalize.css 实现重置样式
import 'normalize.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx'

Vue.prototype.XLSX = XLSX;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
