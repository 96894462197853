<template>
    <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}
// 便于后期设置盒子高度和窗口一致  div{ height: 100%}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
